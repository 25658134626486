@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply text-royal-blue;
  }
}

@layer components {
  .text-display {
    @apply font-primary font-bold text-42 lg:text-56;
  }

  .text-title {
    @apply font-primary font-bold text-32 lg:text-48;
  }

  .text-subtitle {
    @apply font-primary font-normal text-32 lg:text-42;
  }

  .text-subheader {
    @apply font-secondary font-semibold text-24 lg:text-32;
  }

  .text-body {
    @apply font-secondary font-normal text-16;
  }

  .text-header {
    @apply font-secondary font-normal text-14;
  }

  .text-small {
    @apply font-secondary font-medium text-12 lg:tracking-[1%];
  }

  .text-fade {
    @apply relative overflow-hidden before:absolute before:bottom-0 before:right-0 before:w-full before:h-6 before:bg-gradient-to-t before:from-white dark:before:from-gray-900;
  }
}

.main-layout {
  min-height: calc(100vh - 117.58px - 75px); /*Max screen - header - footer */
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

del.diagonal {
  position: relative;
  text-decoration: none;
  margin-right: 8px;
}

del.diagonal:before {
  content: '';
  border-bottom: 1px solid;
  position: absolute;
  width: 110%;
  height: 50%;
  transform: rotate(-12deg);
}

.center-text {
  text-align: center;
}

.overview-buttom-features {
  background: linear-gradient(
    108.24deg,
    #ffefed 7.25%,
    #fff9f2 35.77%,
    #fff0ee 62.56%,
    #fff8f7 90.22%
  );
  opacity: 0.7;
}

.new-sing-in .form-background {
  background: linear-gradient(to bottom, #ffffff, #fcfcfc);
}

.new-sing-in .form-background .content-box {
  box-shadow: 0px 30px 80px rgba(25, 23, 34, 0.1);
}

.custom-color-success {
  color: '#67AD5B' !important;
}

.multi-select-grey-without-border {
  border: none;
}

.multi-select-grey-without-border > div {
  border: none;
  background-color: rgb(241 243 246 / var(--tw-bg-opacity)) !important;
}

.new-sign-up.pricing-step-background {
  background: linear-gradient(180deg, #fdfeff 0%, #e6ebed 68.23%, #f3fbff 100%);
}

.text-prices-launch-color {
  color: #c45312;
}

.text-prices-grow-color {
  color: #5e59d4;
}
.text-prices-scale-color {
  color: #e04e71;
}

.text-prices-community-color {
  color: #c45312;
}

.text-prices-full-color {
  color: #e04e71;
}
.bg-prices-launch-color {
  background-color: #f9eee7;
}

.bg-prices-grow-color {
  background-color: #e2e1f7;
}
.bg-prices-scale-color {
  background-color: #fcedf1;
}

.bg-prices-community-color {
  background-color: #f9eee7;
}

.bg-prices-full-color {
  background-color: #fcedf1;
}
.no-outline-on-focus:focus-visible {
  outline: none;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: grey;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

input:-webkit-autofill,
input:-webkit-autofill:enabled,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:enabled,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:enabled,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #F1F3F6 inset;
  box-shadow: 0 0 0px 1000px #F1F3F6 inset;
  background-color: #F1F3F6 !important;
}

.swiper-wrapper-recommendations {
  .swiper {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .swiper-wrapper {
    margin-bottom: 60px;
  }
}